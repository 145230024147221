import React from "react";
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import ReactDOM from "react-dom";
import "./styles/tailwind.css";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { bootstrapPwa } from "./services/pwa";
import "react-tooltip/dist/react-tooltip.css"; //needed for react-tooltip

// Initialize Apollo Client
const client = new ApolloClient({
  uri: `${process.env.REACT_APP_API_URL}/graphql`,
  cache: new InMemoryCache(),
});


ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <App />
      </BrowserRouter>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

bootstrapPwa();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
